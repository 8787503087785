import React, { Component } from "react";
import Header from "./header";
import Hero from "./hero";
import SearchList from "./searchList";
import Footer from "./footer";


class Search extends Component {

  render() {

    return (
        <div>
            <Header /> 
            <Hero />
            <SearchList />
            <Footer />
        </div>
    );
  }
}

export default Search;
