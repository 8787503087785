import React, { Component } from "react";
import { Navbar, Nav } from 'react-bootstrap';
import { BrowserRouter as Router, Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import image from "../assets/ordrslip_logo.png";

class Header extends Component {

  render() {

    return (
        <Navbar collapseOnSelect expand="lg" className="ordr-nav navbar-dark">
            <a href="https://ordrslip.com/" target="_blank"><Navbar.Brand><img src={image} alt="ordrslip" style={{ height: 40 }} /></Navbar.Brand></a>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Link className="nav-links" to="/cities">All Cities</Link>
                    <a className="nav-links" target="_blank" href="https://ordrslip.com/community/">About Ordrslip</a>
                </Nav>
                <Nav className="v-center-bg">
                    <div className="nav-links small-text">Own a Restaurant? </div>
                    <FontAwesomeIcon className="ordr-arrow" icon={['fas', 'long-arrow-alt-right']} />
                    <a className="justify-content-end" target="_blank" className="ordr-btn nav" href="https://app.ordrslip.com/#/">Sign Up</a>
                </Nav>
            </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
