import React, { Component } from "react";
import Header from "./header";
import Hero from "./hero";
import HomeList from "./homeLists";
import Footer from "./footer";


class Home extends Component {

  render() {

    return (
        <div>
            <Header /> 
            <Hero />
            <HomeList />
            <Footer />
        </div>
    );
  }
}

export default Home;
