import React, { Component } from "react";
import Header from "../header";
import Hero from "../hero";
import Cities from "./cities";
import Footer from "../footer";
import api from "../../services/api";
import { observer } from 'mobx-react';


class AllCities extends Component {
  componentDidMount() {
    api.getClients();
  }

  render() {

    return (
        <div>
            <Header /> 
            <Hero />
            <Cities />
            <Footer />
        </div>
    );
  }
}

export default observer(AllCities);
