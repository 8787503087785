import React, { Component } from "react";
import { observer } from 'mobx-react';
import { BrowserRouter as Router, Link} from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { toJS } from 'mobx';
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GoogleMapReact from 'google-map-react';
import Moment from 'react-moment';
import apple from "../../assets/apple-app-store.png";
import google from "../../assets/google-play-badge.png";
import LearnMore from '../learnMore';
import store from '../../store/store';
import api from "../../services/api";
import config from "../../config/config";
import _ from 'lodash';


class RestaurantInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            store_hours: this.formatHours(store.current_location.store_hours),
            pickup_hours: this.formatHours(store.current_location.pickup_hours),
        }
    }
    
    componentDidMount() {
        let path = window.location.pathname,
        lastPart1 = decodeURIComponent(path.split('/')[2]),
        lastPart2 = decodeURIComponent(path.split('/')[4]);
        
        if (_.isEmpty(toJS(store.current_location))) {
            api.getClients()
            .then((response) => {
                _.forEach(store.cities, city => {
                    if (city.city === lastPart1) {
                        store.updateCurrentCity(city);
                        _.forEach(city.locations, loc => {
                            if (loc.tenant_info.name === lastPart2) {
                                store.updateCurrentLocation(loc);
                                this.setState({loading: false});
                            }
                        })
                    }
                });
            });
        } else {
            this.setState({loading: false});
        }
    }

    normalize(phone) {
        phone = phone.replace(/[^\d]/g, "");
        return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1($2)$3-$4");
    }

    cityCaps(city) {
        var lower = city.toLowerCase();
        return lower.replace(/(^| )(\w)/g, function(x) {
            return x.toUpperCase();
        });
    }

    formatHours(days) {
        let newHours = [
            {
                end_time: "",
                start_time: "",
                weekday: 'Sunday',
            },
            {
                end_time: "",
                start_time: "",
                weekday: 'Monday',
            },
            {
                end_time: "",
                start_time: "",
                weekday: 'Tuesday',
            },
            {
                end_time: "",
                start_time: "",
                weekday: 'Wednesday',
            },
            {
                end_time: "",
                start_time: "",
                weekday: 'Thursday',
            },
            {
                end_time: "",
                start_time: "",
                weekday: 'Friday',
            },
            {
                end_time: "",
                start_time: "",
                weekday: 'Saturday',
            },
        ]
        _.forEach(days, day => {
            _.forEach(newHours, newHour => {
                if (day.weekday === newHour.weekday) {
                    newHour.start_time = day.start_time;
                    newHour.end_time = day.end_time;
                }
            });
        });
       return newHours;
    }

    returnCity() {
        let path = window.location.pathname,
        lastPart = decodeURIComponent(path.split('/')[2]);        
        
        if (store.place.locality && store.place.locality.length){
            if(store.place.locality !== 'long_name') {
                return store.place.locality;
            } else {
                return 'Local'; 
            }
        } else if (lastPart !== 'undefined' ) {
            return this.cityCaps(lastPart);
        }  else {
            return 'Local' 
        }
    }

    goBack(){
        this.props.history.goBack();
    }

    render() {
        const Marker = (props: any) => {
            const {click} = props;
            return(
                <div style={{ cursor: 'pointer' }}>
                    <div className="pin"></div>
                    <FontAwesomeIcon className="food-icon" icon={['fas', 'utensils']} />
                    <div className="map-name">{click.tenant_info.name}</div>
                </div>
            )
        };

        return (    
            <div>
                <div className="small-hero">
                    <Row>
                        <Col>
                            <div className="community-header">Keep {this.returnCity()} Restaurants Cooking</div>
                            <button className="rest-info-back" onClick={() => this.props.history.push(`/resources/${store.current_city.city}/`)}>Back to All</button>
                        </Col>
                </Row>
                </div>            
                {this.state.loading ? 
                <div className="spinner">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner> 
                </div>:
                <Container fluid className="page-contain">
                    <div className="rest-info-contain">
                        <Row className="rest-info">
                            <Col md={12} lg={8}>
                                <Row>
                                    <Col className="rest-info-icon" md={12} lg={4}>
                                        <img src={store.current_location.logo} alt="Logo" className="location-img"/>
                                    </Col>
                                    <Col className="rest-info-content" md={12} lg={8}>
                                        <div className="rest-info-header">{store.current_location.tenant_info.name}</div>
                                        <div className="tags-contain">
                                            {store.current_location.tenant_info.tags.map(tag => 
                                                <div className="rest-info-tags">{tag}</div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="rest-info-download" md={12} lg={4}>
                                <div className="rest-info-pickup">Pickup {!_.isEmpty(toJS(store.current_location.delivery_hours)) ? ' & Delivery' : null}</div>
                                {store.current_location.tenant_info.app_ios_url ? 
                                <Row className="social-pad">
                                    <Col xs={6} className="no-padding">
                                        <a href={store.current_location.tenant_info.app_ios_url} rel="noopener noreferrer" target="_blank">
                                            <img src={apple} alt="app store logo" className="apple"/>
                                        </a>
                                    </Col>
                                    <Col xs={6} className="no-padding">
                                        <a href={store.current_location.tenant_info.app_android_url} rel="noopener noreferrer" target="_blank">
                                            <img src={google} alt="google play logo" className="google"/>
                                        </a>
                                    </Col>
                                </Row> : 
                                <Row>
                                    <Col xs={12} className="download">Coming Soon!</Col>
                                </Row>}
                            </Col>
                        </Row>                 
                        <div className="rest-info-box">
                            <div style={{ height: '300px', width: '100%'}}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: config.google_maps_key }}
                                    defaultCenter={{ lat: store.current_location.latlon.coordinates[1], lng: store.current_location.latlon.coordinates[0]}}
                                    defaultZoom={15} >
                                    <Marker
                                        lat={store.current_location.latlon.coordinates[1]}
                                        lng={store.current_location.latlon.coordinates[0]}
                                        click={store.current_location}
                                        /> 
                                </GoogleMapReact>
                            </div>
                            <Row className="rest-info-contact">
                                <Col lg={4}>
                                    <div className="rest-info-name">{this.cityCaps(store.current_location.name)}</div>
                                    <div>
                                        <div className="rest-info-title">Address</div>
                                        <div className="rest-info-details">
                                            {store.current_location.address.address_line_1}
                                        </div>
                                        <div className="rest-info-details">
                                            {store.current_location.address.address_line_2}
                                        </div>
                                        <div className="rest-info-details">
                                            {store.current_location.address.city} {store.current_location.address.state} {store.current_location.address.postal_code}
                                        </div>
                                    </div>
                                    <div className="rest-info-pad">
                                        <div className="rest-info-title">Phone</div>
                                        <div className="rest-info-details">
                                            {this.normalize(store.current_location.phone)}
                                        </div>
                                    </div>
                                    {store.current_location.tenant_info.website_url ? 
                                    <div className="rest-info-pad">
                                        <div className="rest-info-title">Website</div>
                                        <a className="rest-info-details" target="_blank" href={store.current_location.tenant_info.website_url}>{store.current_location.tenant_info.website_url}</a>
                                    </div>: null}
                                    {store.current_location.tenant_info.yelp_url !== '' || store.current_location.tenant_info.facebook_url !== '' || store.current_location.tenant_info.twitter_url !== '' || store.current_location.tenant_info.instagram_url !== '' ?
                                    <div className="rest-info-pad">
                                        <div className="rest-info-title">Follow Us</div>
                                        <div className="rest-info-social-contain">
                                            {store.current_location.tenant_info.facebook_url !== '' ? <a href={store.current_location.tenant_info.facebook_url} target="_blank" className="rest-info-social"><FontAwesomeIcon className="rest-info-social-icon facebook" icon={['fab', 'facebook-f']} /></a> : null}
                                            {store.current_location.tenant_info.instagram_url !== '' ? <a href={store.current_location.tenant_info.instagram_url} className="rest-info-social"><FontAwesomeIcon className="rest-info-social-icon instagram" icon={['fab', 'instagram']} /></a> : null}
                                            {store.current_location.tenant_info.twitter_url !== '' ? <a href={store.current_location.tenant_info.twitter_url} className="rest-info-social"><FontAwesomeIcon className="rest-info-social-icon twitter" icon={['fab', 'twitter']} /></a> : null}
                                            {store.current_location.tenant_info.yelp_url !== '' ? <a href={store.current_location.tenant_info.yelp_url} className="rest-info-social"><FontAwesomeIcon className="rest-info-social-icon yelp" icon={['fab', 'yelp']} /></a> : null}
                                        </div>
                                    </div> : null}
                                </Col>
                                <Col lg={4}> 
                                    <div>
                                        <div className="rest-info-name">Store Hours</div>
                                        {this.state.store_hours.map(hours => 
                                            <Row className="rest-info-details">
                                                <Col>
                                                    <div className="days">{hours.weekday}</div>
                                                </Col>
                                                <Col>
                                                    {hours.start_time !== '' ?
                                                    <div className="hours"><Moment parse="HH:mm" format="h:mm a">{hours.start_time}</Moment> - <Moment parse="HH:mm" format="h:mm a">{hours.end_time}</Moment></div>:
                                                    <div>Closed</div>}
                                                </Col>                                        
                                            </Row>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={4}> 
                                    <div>
                                        <div className="rest-info-name">Pickup Hours</div>
                                        {this.state.pickup_hours.map(hours => 
                                            <Row className="rest-info-details">
                                                <Col>
                                                    <div className="days">{hours.weekday}</div>
                                                </Col>
                                                <Col>
                                                    {hours.start_time !== '' ?
                                                    <div className="hours"><Moment parse="HH:mm" format="h:mm a">{hours.start_time}</Moment> - <Moment parse="HH:mm" format="h:mm a">{hours.end_time}</Moment></div>:
                                                    <div>Closed</div>}
                                                </Col>                                        
                                            </Row>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row className="cities-btn">
                            <Button onClick={() => this.props.history.push(`/resources/${store.current_city.city}/`)} className="ordr-btn selection">Back to All</Button>
                        </Row>
                    </div>
                <LearnMore />
                </Container>}
            </div> 
        );
    }
}

export default withRouter(observer(RestaurantInfo));
