import React, { Component } from "react";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import LearnMore from './learnMore';
import CityList from './cityList';
import apple from "../assets/apple-app-store.png";
import google from "../assets/google-play-badge.png";
import store from '../store/store';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import _ from 'lodash';
import api from "../services/api";

class SearchList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    
    componentDidMount() {
        this.setState({loading: false});
    }

    restaurantInfo(restaurant) {
        store.updateCurrentLocation(restaurant);
        this.props.history.push(`/resources/${store.current_city.city}/restaurant/${restaurant.tenant_info.name}`)
    }

  render() {

    return (
        <div>
            <Container fluid className="page-contain">
                {this.state.loading ? 
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner> :
                <div>
                    <Row className="all-cities-header">
                        <Col xs={12}>
                            {store.address.length ? 
                            <div className="header">Food near {store.address}</div> : 
                            <div className="header">Search again</div>}
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    {store.close_locs.length > 0 ? 
                    <Row> 
                        {store.close_locs && store.close_locs.map(location =>
                            <Col xs={12} lg={6} xl={4}>
                                <Row onClick={() => {this.restaurantInfo(location)}} className="location-box">
                                    <Col xs={6} sm={5} lg={4} className="logo-img">
                                        <img src={location.logo} alt="Logo" className="location-img"/>
                                    </Col>
                                    <Col xs={6} sm={7} lg={8} className="search-detail">
                                        <div className="loc-name">{location.tenant_info.name}</div>
                                        <div className="loc-address">{location.address.address_line_1}</div>
                                    </Col>
                                </Row> 
                            </Col>
                        )}
                    </Row> : 
                    <div className="no-locs-body">
                        <div className="no-locations-head">Looks like there aren’t any restaurants in your area using Ordrslip</div>
                        <div className="no-locations-subhead">Want to be the first? 
                        <a className="no-locations-subhead" rel="noopener noreferrer" href="https://ordrslip.com/" target="_blank"><div>Learn More</div></a></div>
                    </div>}
                </div>}
                <CityList />
                <LearnMore />
            </Container>
        </div>
    );
  }
}

export default withRouter(observer(SearchList));
