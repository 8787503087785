import React, { Component } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import merced from "../assets/takeout/takeout-merced.png";
import oakland from "../assets/takeout/takeout-oakland.png";
import bakersfield from "../assets/takeout/takeout-bakersfield.png";
import fresno from "../assets/takeout/takeout-fresno.png";

import api from "../services/api";
import store from "../store/store";
import config from "../config/config";
import geocoder from '../services/geocoder';


const selectStyles = {
    control: (provided) => ({
        ...provided,
        paddingLeft: '40px',
        height: '50px',
        width: 'calc(100% - 10px)',
        fontSize: '16px',
        color: '#919191',
        borderRadius: 'none',
    }),
    menu: (provided) => ({
        ...provided,
        fontSize: '16px',
        borderRadius: 'none',
        textAlign: 'left'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        visibility: 'hidden'
    }),

}

class Hero extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locationMatched: [],
            address: '',
        }
        this.selectAddress = this.selectAddress.bind(this);
    };

    componentDidMount() {
        api.getClients();
    }

    findLocations() {
        let loc_array = [];
        for (let i = 0; i < store.clients.length; i++) {
            let loc = store.clients[i];
            let dist = this.getDistance(store.coords.lat, store.coords.lng, loc.latlon.coordinates[1], loc.latlon.coordinates[0]);
            if (dist < 20){
                loc_array.push(loc);
            }
        }
        store.updateCloseLocs(loc_array);
        store.updateAddress(this.state.address);
        this.props.history.push(`/search?q=${store.address}`)
    }

    getDistance(lat1, lon1, lat2, lon2) {
        let R = 6371; // km
        let dLat = this.toRad(lat2-lat1);
        let dLon = this.toRad(lon2-lon1);
        let lata = this.toRad(lat1);
        let latb = this.toRad(lat2);
        
        let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lata) * Math.cos(latb); 
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        let d = R * c;
        return d;
    }

    toRad(Value) {
        return Value * Math.PI / 180;
    }

    selectAddress(selectedAddress) {
        geocoder.geocodeAddress(selectedAddress.label)
            .then(response => {
                this.setState({address: selectedAddress})
                store.updateCoords(response);
            })
    }

    cityCaps(city) {
        var lower = city.toLowerCase();
        return lower.replace(/(^| )(\w)/g, function(x) {
            return x.toUpperCase();
        });
    }

    returnCity() {
        let path = window.location.pathname,
        lastPart = decodeURIComponent(path.split('/')[2]);        
        
        if (lastPart !== 'undefined' ) {
            return this.cityCaps(lastPart);
        } else if (store.place.locality && store.place.locality.length){
            if(store.place.locality !== 'long_name') {
                return store.place.locality;
            } else {
                return 'Local'; 
            }
        } else {
            return 'Local' 
        }
    }

    returnLogo() {
        let path = window.location.pathname,
        lastPart = decodeURIComponent(path.split('/')[2]);

        if (lastPart == "OAKLAND") {
            return <img src={oakland} alt="oakland logo" className="city-logo"/>
        } else if (lastPart == "MERCED") {
            return <img src={merced} alt="merced logo" className="city-logo"/>
        } else if (lastPart == "Bakersfield") {
            return <img src={bakersfield} alt="merced logo" className="city-logo"/>
        } else if (lastPart == "FRESNO") {
            return <img src={fresno} alt="fresno logo" className="city-logo"/>
        }
    }

    render() {
        return (
            <div className="hero">
                <Row>
                    <Col>
                        {this.returnLogo()}
                        <div className="community-header">Keep {this.returnCity()} Restaurants Cooking</div>
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={10} lg={6}>
                                <div className="sub-community-header">Many restaurants and eateries are adapting their services to take-out orders during the COVID-19 pandemic. In support of this, Ordrslip has created mobile ordering apps for local restaurants in your community for free. Find a local restaurant near you to continue to show your support.</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="search-bar">
                    <Col className="d-none d-sm-block"></Col>
                    <Col className="no-padding" xs={12} sm={6} md={4}>
                        <div className="add-icon">
                            <FontAwesomeIcon className="loc-icon" icon={['fa', 'map-marker-alt']} />
                            <GooglePlacesAutocomplete
                                apiKey={config.google_maps_key}
                                selectProps={{ 
                                    className: "search-right",
                                    styles: selectStyles,
                                    placeholder: 'Enter Your Address',
                                    onChange: this.selectAddress
                                }}
                                />
                        </div>
                    </Col>
                    <Col className="no-padding" xs={12} sm={4} md={2}>
                        <Button onClick={() => {this.findLocations()}} className="search-btn left">Find Food</Button>
                    </Col>
                    <Col className="d-none d-sm-block"></Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(observer(Hero));
