import React, { Component } from "react";
import Header from "../header";
import Hero from "../hero";
import Resources from "./resources";
import Footer from "../footer";


class AllResources extends Component {

  render() {

    return (
        <div>
            <Header /> 
            <Hero />
            <Resources />
            <Footer />
        </div>
    );
  }
}

export default AllResources;
