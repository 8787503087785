import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './styles/ordrslip.scss';
import Home from './components/home';
import AllCities from "./components/cities/allCities";
import AllResources from "./components/resources/allResources";
import Restaurant from "./components/restaurant/restaurant";
import Search from "./components/search";
import ScrollToTop from "./components/scroll";
import store from "./store/store";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons';

library.add(fal, fas, fab, fad)

class App extends Component {
  render() {
    return (
        <Router>
            <ScrollToTop>
                <Switch>
                    <Route path="/search" component={Search} />
                    <Route path="/cities" component={AllCities} />
                    <Route path="/resources/:id" exact={true} component={AllResources} />
                    <Route path="/resources/:id/restaurant" component={Restaurant} />
                    <Route exact path="/" component={Home} />
                </Switch>
            </ScrollToTop>
        </Router>
    );
  }
}

export default App;
