import React, { Component } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from "../assets/ordrslip_logo.png";

class Footer extends Component {

  render() {

    return (
        <div>
            <div className="sub-footer">
                <Row>
                    <Col className="logo-centered">
                        <img src={logo} alt="ordrslip" style={{ height: 40 }} />
                    </Col>
                </Row>
                <Row className="social-row justify-content-sm-center">
                    <Col xs={3} sm={2} lg={1} className="h-center-col">
                        <a target="_blank" href="https://www.facebook.com/ordrslip/" className="social-icon"> 
                            <FontAwesomeIcon className="social-icons facebook" icon={['fab', 'facebook-f']} />
                        </a>
                    </Col>
                    <Col xs={3} sm={2} lg={1} className="h-center-col">
                        <a target="_blank" href="https://www.instagram.com/ordrslip/" className="social-icon"> 
                            <FontAwesomeIcon className="social-icons" icon={['fab', 'instagram']} />
                        </a>
                    </Col>
                    <Col xs={3} sm={2} lg={1} className="h-center-col">
                        <a target="_blank" href="https://twitter.com/ordrslip" className="social-icon"> 
                            <FontAwesomeIcon className="social-icons" icon={['fab', 'twitter']} />
                        </a>
                    </Col>
                    <Col xs={3} sm={2} lg={1} className="h-center-col">
                        <a target="_blank" href="https://www.linkedin.com/company/ordrslip/" className="social-icon"> 
                            <FontAwesomeIcon className="social-icons" icon={['fab', 'linkedin']} />
                        </a>
                    </Col>
                </Row>
            </div>        
            <div className="footer page-contain">
                <Row className="height-100">
                    <Col xs={12} md={6} xl={8} className="v-center-col">
                        <div className="footer-txt">© 2020 Ordrslip™. All Rights Reserved.</div>
                    </Col>
                    <Col xs={12} md={2} xl={1} className="v-center-col">
                        <a href="https://ordrslip.com/privacy-policy/" target="_blank" className="footer-txt">Privacy Policy</a>
                    </Col>
                    <Col xs={12} md={2} xl={1} className="v-center-col">
                        <a href="https://ordrslip.com/terms-of-use/" target="_blank" className="footer-txt">Terms of Use</a>
                    </Col>
                    <Col xs={12} md={2} xl={1} className="v-center-col">
                        <a href="https://ordrslip.com/contact/" target="_blank" className="footer-txt">Contact</a>
                    </Col>
                </Row>
            </div>
        </div>
    );
  }
}

export default Footer;
