import React, { Component } from "react";
import Header from "../header";
import RestaurantInfo from "./restaurantInfo";
import Footer from "../footer";


class Restaurant extends Component {

  render() {

    return (
        <div>
            <Header /> 
            <RestaurantInfo />
            <Footer />
        </div>
    );
  }
}

export default Restaurant;
