import React, { Component } from "react";
import { Row, Col, Button } from 'react-bootstrap';

class LearnMore extends Component {

  render() {

    return (
        <Row className="resource-footer">
            <Col xs={12}>
                <div className="resource-header">Own a Restaurant?</div>
                <div className="resource-subheader">We’ll Help You Pivot to a Takeout-only Model for FREE</div>
            </Col>
            <Col xs={12} className="h-center-col">
                <Button href="https://ordrslip.com/landing-page/we-are-all-in-this-together/" target="_blank" className="ordr-btn right learn">Learn More!</Button>
            </Col>
        </Row>
    );
  }
}

export default LearnMore;
