import config from "../config/config";
import store from "../store/store";
const GOOGLE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json?'


class geocoder {
    static geocodeLatLng(position) {
        let url = `${GOOGLE_API_URL}latlng=${position.coords.latitude},${position.coords.longitude}&key=${config.google_maps_key}`;
        return new Promise((resolve, reject) =>
            fetch(url, { method: "GET" })
                .then(response => response.json())
                .then(response => {
                    let address = response.results[0].formatted_address;
                    resolve(address);
                }))
    }

    static geocodeAddress(destination) {
        let url = `${GOOGLE_API_URL}&address=${destination}&key=${config.google_maps_key}`;
        return new Promise((resolve, reject) =>
            fetch(url, { method: "GET" })
                .then(response => response.json())
                .then(response => {
                    let place = response.results[0];
                       let componentForm = {
                            street_number: 'short_name',
                            route: 'long_name',
                            locality: 'long_name',
                            administrative_area_level_1: 'short_name',
                            country: 'long_name',
                            postal_code: 'short_name'
                        };

                        for (let i = 0; i < place.address_components.length; i++) {
                            const addressType = place.address_components[i].types[0];
                            if (componentForm[addressType]) {
                                const val = place.address_components[i][componentForm[addressType]];
                                componentForm[addressType] = val;
                            }
                        }

                        store.updatePlace(componentForm);

                    let coords = response.results[0].geometry.location;
                    resolve(coords);
                }))
    }
}

export default geocoder;