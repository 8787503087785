import React, { Component } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { BrowserRouter as Router, Link} from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../store/store';
import _ from "lodash";

class CityList extends Component {
    
    updateCurrentLocation(city) {
        store.updateCurrentCity(city);
    }

    getCityList() {
        let cities = _.sortBy(_.concat(store.cities,
            _.map(city => {
                return {city: city, locations: []};
            })
        ), [function(city) {
                return !Boolean(city.locations.length);
            }, 'city']);
        return cities;
    }

    cityCaps(city) {
        var lower = city.toLowerCase();
        return lower.replace(/(^| )(\w)/g, function(x) {
            return x.toUpperCase();
        });
    }

  render() {

    return (
        <div>
            <Row className="cities-header">
                <Col>
                    <div className="header">Cities</div>
                </Col>
                <Col>
                    <Link to="/cities"><div className="header-btn">View all Cities</div></Link>
                </Col>
            </Row>
            <Row>
                {store.cities.length && this.getCityList().map(city => 
                    <Col xs={12} md={6} lg={3}>
                        <Link className="city" to={`/resources/${city.city}`} onClick={() => store.updateselectedCity(city.city)}>
                            <li className="list-group-item city"
                                onClick={() => this.updateCurrentLocation(city)}>{this.cityCaps(city.city)}</li>
                        </Link>
                    </Col>
                )}
            </Row>
            <Row className="cities-btn">
                <Link to="/cities">
                    <Button className="ordr-btn selection">View all Cities</Button>
                </Link>
            </Row>
        </div>
    );
  }
}

export default withRouter(observer(CityList));
