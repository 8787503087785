import React, { Component } from "react";
import { Row, Col } from 'react-bootstrap';
import food from '../assets/food-icon.png';
import deli from '../assets/delivery-icon.png';
import phone from '../assets/phone-icon.png';


class SiteInfo extends Component {

  render() {

    return (  
        <Row className="selection-contain justify-content-md-center">
            <Col xs={12} md={4} lg={3} className="selection">
                <div className="home-info">
                    <img className="home-icon" src={phone} alt="food icon" />
                    <div className="step">1</div>
                    <div className="selection-text">Download App From Local Restaurant</div>
                    <div className="selection-sub-text">It insures that restaurant owners can invest every cent back into their people.</div>
                </div>
            </Col>
            <Col xs={12} md={4} lg={3} className="selection">
                <div className="home-info">
                    <img className="home-icon" src={deli} alt="food icon" />
                    <div className="step">2</div>
                    <div className="selection-text">Pickup or Choose Delivery</div>
                    <div className="selection-sub-text">Don’t forget to tip when ordering food. The service industry depends on tips from customers like you.</div>
                </div>
            </Col>
            <Col xs={12} md={4} lg={3} className="selection">
                <div className="home-info">
                    <img className="home-icon" src={food} alt="food icon" />
                    <div className="step">3</div>
                    <div className="selection-text">Enjoy Supporting Local</div>
                    <div className="selection-sub-text">When you buy locally you are putting your spending power back into your community.</div>
                </div>
            </Col>
        </Row>
    );
  }
}

export default SiteInfo;

