import { observable } from "mobx";
import _ from 'lodash';

const store = observable({
    address: observable.map(),
    error: observable.map(),
    success: observable.map(),
    clients: observable.map(),
    close_locs: observable.map(),
    cities: observable.map(),
    coords: observable.map(),
    current_location: observable.map(),
    place: observable.map(),
    resources: observable.map(),
    current_city: observable.map(),
    selected_city: observable.map(),
    states: observable.map(),


    updateAddress(address) {
        this.address = address;
    },
    updateCurrentLocation(location) {
        this.current_location = location;
    }, 
    updateError(error) {
        this.error = error;
    },
    updateSuccess(success) {
        this.success = success;
    },
    updateClients(clients) {
        this.clients = clients;
    },
    updateCloseLocs(locs) {
        this.close_locs = locs;
    },
    updateCities(cities) {
        this.cities = cities;
    },
    updatePlace(place) {
        this.place = place; 
    },
    updateResources(resources) {
        this.resources = resources;
    }, 
    updateCurrentCity(city) {
        this.current_city = city;
    }, 
    updateCoords(coords) {
        this.coords = coords;
    },
    updateselectedCity(selected_city) {
        this.selected_city = selected_city;
    },
    updateStates(states) {
        this.states = states;
        let cities = [];
        _.forEach(states, state => {
            cities = _.concat(cities, state.cities);
        });
        this.updateCities(cities);
    }
});

export default store;
