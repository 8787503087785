import React, { Component } from 'react';
import { observer } from "mobx-react";
import { toJS } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom';

import GoogleMapReact from 'google-map-react';
import store from '../../store/store';
import config from "../../config/config";
import geocoder from '../../services/geocoder';


class GoogleMap extends Component {
    defaultProps = {
        center: {
            lat: store.current_city.locations[0].latlon.coordinates[1],
            lng: store.current_city.locations[0].latlon.coordinates[0]
        },
        zoom: 12
    };

     restaurantInfo(restaurant) {
        store.updateCurrentLocation(restaurant);
        this.props.history.push(`/resources/${store.current_city.city}/restaurant/${restaurant.tenant_info.name}`)
    }

    render() {
        const Marker = (props: any) => {
            const {click} = props;
            return(
                <div onClick={() => this.restaurantInfo(click)} style={{ cursor: 'pointer' }}>
                    <div className="pin"></div>
                    <FontAwesomeIcon className="food-icon" icon={['fas', 'utensils']} />
                    <div className="map-name">{click.tenant_info.name}</div>
                </div>
            )
        };

        return (
            <div className="map-styles" style={{ height: '700px', width: '100%'}}>
                {store.current_city.locations ? 
                <GoogleMapReact
                    bootstrapURLKeys={{ key: config.google_maps_key }}
                    defaultCenter={this.defaultProps.center}
                    defaultZoom={this.defaultProps.zoom}
                    center={{ lat: store.current_city.locations[0].latlon.coordinates[1], lng: store.current_city.locations[0].latlon.coordinates[0]}}>
                {store.current_city.locations.map(marker =>
                    <Marker
                        lat={marker.latlon.coordinates[1]}
                        lng={marker.latlon.coordinates[0]}
                        click={marker}
                        /> 
                )}
                </GoogleMapReact> : null}
            </div>
        );
    }
}

export default withRouter(observer(GoogleMap));
