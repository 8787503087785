import _ from "lodash";
import React, { Component } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { BrowserRouter as Router, Link} from "react-router-dom";
import { observer } from 'mobx-react';
import LearnMore from './learnMore';
import CityList from './cityList';
import SiteInfo from './siteInfo';
import store from '../store/store';

class HomeList extends Component {

  render() {

    return (
        <div>
            <Container fluid className="page-contain">
                <SiteInfo />
                <CityList />
                <LearnMore />
            </Container>
        </div>
    );
  }
}

export default observer(HomeList);
