import React, { Component } from "react";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { BrowserRouter as Router, Link} from "react-router-dom";
import StatePicker from '../statepicker';
import LearnMore from '../learnMore';
import SiteInfo from '../siteInfo';
import { observer } from "mobx-react";
import store from "../../store/store";

class Cities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.setState({loading: false});
    }

    updateCurrentLocation(city) {
        store.updateCurrentCity(city);
    }

    cityCaps(city) {
        var lower = city.toLowerCase();
        return lower.replace(/(^| )(\w)/g, function(x) {
            return x.toUpperCase();
        });
    }

  render() {

    return (
        <div>
            {this.state.loading ? 
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner> :
            <Container fluid className="page-contain">
                <SiteInfo />
                <Row className="all-cities-header">
                    <Col xs={12} md={6}>
                        <div className="header">All Cities in the United States</div>
                    </Col>
                    <Col xs={12} md={6}>
                        <StatePicker />
                    </Col>
                </Row>
                {store.states.length && store.states.map(state =>
                <div>
                    <Row>
                        <Col>
                            <div className="city-headers">{state.state}</div>
                        </Col>
                    </Row>
                    <Row> 
                        {state.cities && state.cities.map(location =>
                            <Col xs={12} md={6} lg={3}>
                                <Link className="city" to={`/resources/${location.city}`} onClick={() => store.updateselectedCity(location.city)}><li class="list-group-item" onClick={() => this.updateCurrentLocation(location)}>{this.cityCaps(location.city)}</li></Link>
                            </Col>
                        )}
                    </Row>
                </div>
                )}
                <LearnMore />
            </Container>}
        </div>
    );
  }
}

export default observer(Cities);
