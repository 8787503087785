import React, { Component } from "react";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { BrowserRouter as Router, Link} from "react-router-dom";
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { toJS } from 'mobx';
import LearnMore from '../learnMore';
import CityList from '../cityList';
import SiteInfo from '../siteInfo';
import GoogleMap from './map';
import store from '../../store/store';
import api from "../../services/api";
import _ from 'lodash';


class Resource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showMap: false
        }

    }
    
    componentDidMount() {
        let path = window.location.pathname,
        lastPart = decodeURIComponent(path.split("/").pop());

        if (_.isEmpty(toJS(store.current_city))) {
            api.getClients()
            .then((response) => {
                _.forEach(store.cities, city => {
                    if (city.city === lastPart) {
                        store.updateCurrentCity(city);
                        this.setState({loading: false});
                    }else {
                        this.setState({loading: false});
                    }
                });
            });
        } else {
            this.setState({loading: false});
        }
    }

    toggleMap() {
        this.setState({showMap: !this.state.showMap})
    }

    restaurantInfo(restaurant) {
        store.updateCurrentLocation(restaurant);
        this.props.history.push(`/resources/${store.current_city.city}/restaurant/${restaurant.tenant_info.name}`)
    }
    

  render() {
    return (
        <div>
            <Container fluid className="page-contain">
                <SiteInfo />
                {this.state.loading ? 
                <div className="spinner">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner> 
                </div>:
                <div>
                    {store.current_city === undefined || store.current_city.locations && store.current_city.locations.length !== 0 ?
                    <Container fluid>
                        <Row className="all-cities-header">
                            <Col xs={12}>
                                <div className="header">Featured Restaurants in {store.current_city.city}, {store.current_city.locations[0].address.state}</div>
                            </Col>
                        </Row>
                        <Row className="map-contain">
                            <Col className="small-map no-padding">
                                <div className="map-toggle-btn" onClick={() => this.toggleMap()}>{this.state.showMap === true ? 'Show List' : 'Show Map'}</div>
                                {this.state.showMap === true ? 
                                <div className="hidden-map">              
                                    <GoogleMap /> 
                                </div>: null}
                            </Col>
                            <Col className="scroll">
                                <Row> 
                                    {store.current_city.locations.map(resource =>
                                        <Col xs={12}>
                                            <Row onClick={() => {this.restaurantInfo(resource)}} className="location-box">
                                                <div className="logo-img">
                                                    <img src={resource.logo} alt="Logo" className="location-img"/>
                                                </div>
                                                <div className="loc-detail">
                                                    <div className="loc-name">{resource.tenant_info.name}</div>
                                                    <div className="loc-address">{resource.address.address_line_1}</div>
                                                    
                                                    <div className="bold tag-contain">
                                                        {resource.tenant_info.tags.map(tag => 
                                                            <div className="tag">{tag}</div>
                                                        )}
                                                        </div>
                                                </div>
                                                <div className="tags">
                                                    <div className="loc-tag">Pickup {!_.isEmpty(toJS(resource.delivery_hours)) ? ' & Delivery' : null}</div>
                                                </div>
                                            </Row>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                            <Col className="gutter no-padding">                
                                <GoogleMap />
                            </Col>
                        </Row>
                    </Container> : 
                    <div className="no-locs-body">
                        <div className="no-locations-head">Looks like there aren’t any restaurants in your area using Ordrslip</div>
                        <div className="no-locations-subhead">Want to be the first? 
                        <a className="no-locations-subhead" rel="noopener noreferrer" href="https://ordrslip.com/" target="_blank"><div>Learn More</div></a></div>
                    </div>}
                </div>}
                <LearnMore />
                <CityList />
            </Container>
        </div>
    );
  }
}

export default withRouter(observer(Resource));
