import config from "../config/config";
import store from "../store/store";
import _ from 'lodash';

const api_url = config.api_url;

export default class api {
    static getClients() {
        return new Promise((resolve, reject) => {
            fetch(api_url + "tenants/clients/", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let clients = response.clients;
                    store.updateClients(clients);

                    let resources = {};
                    _.forEach(response.clients, (location) => {
                        _.defaults(resources, _.fromPairs([[location.address.state, {}]]))
                        _.defaults(resources[location.address.state], _.fromPairs([[location.address.city, []]]))
                        resources[location.address.state][location.address.city].push(location)
                    })

                    resources = _.sortBy(_.map(_.toPairs(resources), ([state, cities]) => {
                        return {
                            state: state,
                            cities: _.sortBy(_.map(_.toPairs(cities), ([city, locations]) => {
                                return {
                                    city: city,
                                    locations: _.sortBy(locations, ['name'])
                                }
                            }), ['city'])
                        }
                    }), ['state'])
                    store.updateStates(resources);
                    resolve(resources);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

}
